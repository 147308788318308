import React, { useEffect } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import terms from "../assets/images/faq/terms.jpg"
import list from "../assets/images/faq/S1.png"
import pencilIcon from "../assets/images/faq/pencilIcon.svg"
import moveIcon from "../assets/images/faq/moveIcon.svg"
import chromeSettingsIcon from "../assets/images/faq/chrome-microphone.jpg"
import soundIcon from "../assets/images/faq/soundIcon.svg"
import deleteIcon from "../assets/images/faq/deleteIcon.svg"
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import BackLink from "../components/BackLink";
import { getQRKey } from "../helpers/URLHelper";
import NavLogo from "../components/NavLogo";



export default function FAQs () {

  useEffect( () => {
    document.title = 'FAQ | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "Get answers to all your frequently asked questions about Hallmark video greeting cards, from how to edit a video to earning Crown Rewards points.";
      
    TrackingHelper.trackPage();
  }, [] );

  return(

    <div className="screen-wrap" >
      { getQRKey() ? 
        <NavLogo />
        :
        <NavBar />
      }
      <MainContent className="container">
        <BackLink title="Back"/>
        <h1 className="headline-text">FAQs</h1>
        <br/>


        <h4>ACCOUNT LOG IN QUESTIONS</h4>
        <br/>

        <h5>Why doesn’t my Hallmark.com Account username and password work?</h5>
        <p>Hallmark Video Greeting Cards are created in a system that is separate from your Hallmark.com account. If you do not currently have an account on Hallmark Video Greeting Cards, you will need to create one.</p>
        <br/>

        <h5>How do I log back into my account?</h5>
        <p>If you are creating a Video Greeting from a physical card, you can rescan your card to continue.</p>
        <p>You can also access your account from any computer or phone. Go to hallmarkvideogreetingcards.com and SIGN IN with your email and password. Your videos that are in progress or completed will be there.</p>
        <br/>

        <h5>What if I forget my password?</h5>
        <p>Click  on the &ldquo;<a href="/password-reset"><strong>Forgot password?</strong>&rdquo;</a> link from the sign in screen and you will be prompted to provide your email.&nbsp; An option to reset your password will be sent to the email address associated with your account.</p>
        <br/>



        <h4>SENDING AND SHARING YOUR VIDEO GREETING</h4>
        <br/>

        <h5>How do I send my Video Greeting?</h5>
        <ul>
          <li>Physical card &ndash; After you have finished your greeting, you can simply mail your card to the recipient.&nbsp; When they scan the QR code in the card from their phone, their Video Greeting will play.</li>
          <li>Digital card &ndash; You will be prompted to share your Video Greeting after you have finished your greeting. Share by Email, Text, or download.</li>
        </ul>
        <br/>

        <h5>Is there a time limit for a recipient to download a Video Greeting?</h5>
        <p>Please ensure all videos are downloaded by August 20, 2025</p>
        <br/>

        <h5>How do I download my Video Greeting?</h5>
        <p>When someone receives a Video Greeting, they will see a link to “Download Video” directly below the video they play.  Click on this link to download the video. The video will be downloaded to the default location for downloads for your phone or computer depending on which device you are using.</p>
        <br/>

        <h5>I keep downloading my video, but I can’t find it.</h5>
        <p>If you are on an Apple phone your downloads are going to you FILES folder on your phone. Open this folder and look in “downloads.” Once you find the video in that download folder you can tap it and save it to your phone gallery, so it is easier to access.</p>
        <br />

        <h5>Can I post my Video Greeting on social media?</h5>
        <p>If you want to post your Video Greeting to any social media platform, download the greeting first and then upload it to your post. </p>
        <br/>

        <h5>How can I display or project my Video Greeting on a television?</h5>
        <p>You can download your Video Greeting Card on to a thumb drive and if your TV supports that function. You can also connect your computer to your TV screen with HDMI cables or screen cast through apple TV. </p>
        <p>Here are instructions for Apple Airplay: <a href="https://support.apple.com/en-us/HT204289">https://support.apple.com/en-us/HT204289</a></p>
        <p>Here are instructions for Chromecast:&nbsp; <a href="https://support.google.com/chromecast/answer/6006232?hl=en">https://support.google.com/chromecast/answer/6006232?hl=en</a></p>
        <br/>



      </MainContent>
      <Footer hideMobile={true} />
    </div>
  );
}