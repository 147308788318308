import React, { Component } from "react";
import { apiFetch, generateDeviceId, isLoggedIn, saveSession  } from "../helpers/APIHelper";
import { Link } from "react-router-dom";
import "../assets/css/SignIn.css";
import { getQRKey, getBaseURL} from "../helpers/URLHelper";
import { toastManager } from "../components/Toaster";
import TrackingHelper from "../helpers/TrackingHelper";
import Spinner from "../components/Spinner";
import { Button } from "react-bootstrap";
import Notify from "./Notify";

var EMAIL_REGEX = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class QuickSignup extends Component {
  constructor(props) {
    super(props);

    var redirect = null;
    var redirectEncoded = "";
    var fizzId = null;
    if ( props.match ){
      redirectEncoded = props.match.params.redirect;
      redirect = decodeURIComponent( redirectEncoded || "");
      fizzId = props.match.params.fizzId;
    }
      

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      marketingOptIn: true, // on all the time
      input: {},
      errors: {},
      redirect: redirect,
      redirectEncoded: redirectEncoded,
      isQRCard: false,
      isSubmittingRegister: false,
      fizzId: fizzId,
      showLogIn: false,
      from: this.props.from || "quickSignUp"
    };
  }

  componentDidMount() {
    document.title = 'Create an Account | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "";
    TrackingHelper.trackPage();
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0 && this.state.password.length < 70;
  }

  handleChange = (event) => {

    var value = event.target.value;
    if( event.target.type=='checkbox' ){
      value = event.target.checked;
    }
    this.setState({
      [event.target.name]: event.target.value,
      errors:{}
    });

    console.log("Handle Change", event.target.name, value );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({ 
      loginErrorMessage: null,
    });

    if (this.validate()) {
      this.setState({ 
        isSubmittingRegister: true
      });

      var from = null;

      var data = {
        qrKey: getQRKey(),
        version: "web",
        from: from,
        user: {
          deviceType: 3, // 3 == web
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          password: this.state.password,
          marketingOptIn: this.state.marketingOptIn,
          offer: this.state.offer,
          deviceId: generateDeviceId(),
          userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
      };
      apiFetch(
        this.state.showLogIn ? "/rest/user/login?type=site" : "/rest/user/register-react?type=web&screen=" + this.state.from,
        {
          method: "POST",
          data: data,
        },
        (json) => {
          saveSession(json);
          
          if( !this.state.showLogIn ){
            toastManager.showToast({
              title: 'Account created!' ,
              message: 'You’ll receive an email confirming your new Hallmark Video Greetings account.'
            });
          }

          this.setState({
            isSubmittingRegister: false
          });

          // these need updated
          if ( this.props.successCallback && this.props.preventRedirect ) {
            this.props.successCallback(json);
            return;
          }else if( this.props.isCheckout) {
            this.props.history.push( '/checkout/' + this.state.fizzId )
          } else if ( this.props.isThankyou || json.isBoxSet || json.isQRCard ){

            if( this.props.redirect == 'isQRBoxSet' || json.isBoxSet ){
              window.location = "/thank-you-qr-box/"+this.state.fizzId
              return;
            } else if ( this.props.redirect == 'isQRCard' || json.isQRCard ) {
              window.location = "/thank-you-qr/"+this.state.fizzId
              return;
            } else if ( this.props.redirect == 'isThankyou' ) {
              window.location = "/thank-you-reply/" + this.state.fizzId;
              return;
            } else if ( this.props.redirect == 'free' ) { 
              window.location = "/thank-you-free/"+this.state.fizzId
              return;
            }
            
          } else {
            window.location = /thank-you-digital/+this.state.fizzId
          }

        },
        (error) => {
          console.log("Error::", error);
          this.setState({
            loginErrorMessage: error ? error.message : error + "",
            isSubmittingRegister: false
          });
        }
      );
      console.log(this.state);

      let input = {};
      input["name"] = "";
      input["email"] = "";
      input["password"] = "";
      input["confirm_password"] = "";
      this.setState({ input: input });
    } 

    console.log(this.state.errors);
  };

  disableSubmitButton = () => {
    if( this.state.showLogIn ){

      // first check each form field is not null
      if( !this.state.email || !this.state.password ){
        return true;
      }
      
      // then make sure each field has a value 
      if ( this.state.email.length < 1 || this.state.password.length < 1 ){
        return true;
      }


    } else {

      // first check each form field is not null
      if( !this.state.firstName || !this.state.lastName || !this.state.email || !this.state.password || !this.state.confirmPassword ){
        return true;
      }
      
      // then make sure each field has a value 
      if ( this.state.firstName.length < 1 || this.state.lastName.length < 1 || this.state.email.length < 1 || this.state.password.length < 1 || this.state.confirmPassword.length < 1 ){
        return true;
      }
    }

    // finally check if submitting 
    return this.state.isSubmittingRegister;
  }

  validateFirstName = () =>{
    if (!this.state.firstName) {
      const errors = {firstName : "Please enter your first name."};
      this.setState(() => ({errors}));
    }
    else{
      this.setState(() => ({errors: {}}));
    }
  }

  validateLastName = () =>{
    if (!this.state.lastName) {
      const errors = {lastName : "Please enter your last name."};
      this.setState(() => ({errors}));
    }
    else{
      this.setState(() => ({errors: {}}));
    }
  }

  validateEmail = () =>{

    if (!this.state.email) {
      const errors = {email : "Please enter your email address."};
      this.setState(() => ({errors}));
    }
    else if(!EMAIL_REGEX.test(this.state.email)){
        const errors = {email : "Please enter valid email address."};
        this.setState(() => ({errors}));
      }
    else{
      this.setState(() => ({errors: {}}));
    }
  }

  validatePassword = () =>{
    if (!this.state.password) {
      const errors = {password : "Please enter your password."};
      this.setState(() => ({errors}));
    }
    else if(this.state.password.length < 6){
      const errors = {password : "Please enter a password of at least 6 characters."};
      this.setState(() => ({errors}));
    }
    else{
      this.setState(() => ({errors: {}}));
    }
  }

  validateConfirmPassword = () =>{
    if (!this.state.confirmPassword) {
      const errors = {confirmPassword : "Please confirm your password."};
      this.setState(() => ({errors}));
    }
    else  if (
      typeof this.state.password !== "undefined" &&
      typeof this.state.confirmPassword !== "undefined"
    )
    if(this.state.password != this.state.confirmPassword){
      const errors = {confirmPassword : "Passwords don't match."};
      this.setState(() => ({errors}));
    }
    else{
      this.setState(() => ({errors: {}}));
    }
  }


  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if( this.state.showLogIn ){

      if (!this.state.email) {
        isValid = false;
        errors.email = "Please enter your email address.";
      }

      if (typeof this.state.email !== "undefined") {
        if (!EMAIL_REGEX.test(this.state.email)) {
          isValid = false;
          errors.email = "Please enter valid email address.";
        }
      }

      if (this.state.password.length < 6 || this.state.password.length > 70 ) {
        isValid = false;
        errors.password =
          "Please enter a password between 6 and 70 characters in length.";
      }
      
      if (!this.state.password) {
        isValid = false;
        errors.password = "Please enter your password.";
      }

    } else {

      if (!this.state.firstName) {
        isValid = false;
        errors.firstName = "Please enter your first name.";
      }

      if (!this.state.lastName) {
        isValid = false;
        errors.lastName = "Please enter your last name.";
      }

      if (!this.state.email) {
        isValid = false;
        errors.email = "Please enter your email address.";
      }

      if (typeof this.state.email !== "undefined") {
        if (!EMAIL_REGEX.test(this.state.email)) {
          isValid = false;
          errors.email = "Please enter valid email address.";
        }
      }

      if (this.state.password.length < 6 || this.state.password.length > 70 ) {
        isValid = false;
        errors.password =
          "Please enter a password between 6 and 70 characters in length.";
      }
      
      if (!this.state.password) {
        isValid = false;
        errors.password = "Please enter your password.";
      }

      if (!this.state.confirmPassword) {
        isValid = false;
        errors.confirmPassword = "Please enter your confirmation password.";
      }

      if (
        typeof this.state.password !== "undefined" &&
        typeof this.state.confirmPassword !== "undefined"
      ) {
        if (this.state.password != this.state.confirmPassword) {
          isValid = false;
          errors.confirmPassword = "The passwords you entered don't match. Please check them and try again.";
        }
      }
    }

    this.setState({
      errors: errors,
    });
    return isValid;
  }


  handlePasswordResetSubmit = (event)=>{
    event.preventDefault();
    this.setState({
      isSending:true
    })


    apiFetch( "/rest/user/send-password-link", {
        method: "POST",
        data: { email: this.state.email },
      },
      (json) => {
        this.setState({
          isSending: false,
          confirmation: "The email has been sent. Check your email for a link to change your password.",
        });

      },
      (error) => {
        this.setState({
          isSending: false,
          errorMessage: error + "",
        });
      }
    );


  }

  hideConfirm = ()=>{
    this.setState({
      confirmation:null,
      showForgotPassword:false
    })
  }



  render() {
    return (
      <React.Fragment>
        { this.state.loading ?
          <Spinner float={true} />
          :
          this.renderContent()
        }
      </React.Fragment>
      
    );
  }

  renderContent() {
    var buttonText = "Create Account";

    if( this.props.isPreCheckout ){
      buttonText = "Checkout";
    } else if( this.state.showLogIn ){
      buttonText = "SIGN IN"
    } else {
      buttonText = "Create Account";
    }
  
    if( this.state.showForgotPassword ){
      var allowPasswordResetSubmit = this.state.email.length > 0 && !this.state.isSending;


      return(
        <div className="container">
           <h1 className="headline-text">Forgot password?</h1>
              <h2 className="subheadline">We’ll send you an email to reset your password for your Hallmark Video Greetings account.</h2>


              { this.state.confirmation && 
                <Notify message={this.state.confirmation} onHide={this.hideConfirm} />
              }
              { !this.state.confirmation &&
                <form onSubmit={this.handlePasswordResetSubmit}>
                  <div className="form-group">
                    <span className="has-float-label">
                      <label for="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        value={this.state.email}
                        onChange={this.handleChange}
                        id="email"
                        required={true}
                        maxLength={180}
                      />
                    </span>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-block btn-lg-inline-block btn-primary mt-2"
                    disabled={!allowPasswordResetSubmit}
                  >
                    Send Reset Password Link
                  </button>

                  { this.state.isSending &&
                    <div>
                      <Spinner />
                    </div>
                  }

                  {this.state.errorMessage && (
                    <p className="alert alert-danger mt-2">
                      {this.state.errorMessage}
                    </p>
                  )}
                </form>
              }
        </div>
      );
    }

    return (
      <div className="container">
        <form className="sign-form" onSubmit={this.handleSubmit}>
          { this.state.showLogIn ? (
            <>
               <h1 className="headline-text mb-4">Sign in</h1>

               <div className={`mb-1 form-group ${this.state.errors.email ? "invalid": ""}`}>
                <span className="has-float-label">
                  <label for="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={this.state.email}
                    onBlur ={this.validateEmail}
                    onChange={this.handleChange}
                    id="email"
                    required
                    maxLength={180}
                  />
                </span>
                <small className="text-danger">
                  {this.state.errors.email}
                </small>
              </div>

              <div className={`form-group ${this.state.errors.password ? "invalid": ""}`}>
                <span className="has-float-label">
                  <label for="Password">Password</label>
                  <input
                    className="form-control"
                    type="password"
                    name="password"
                    value={this.state.password}
                    onBlur ={this.validatePassword}
                    onChange={this.handleChange}
                    id="password"
                    maxLength={70}
                    required
                  />
                </span>
                <small className="text-danger">
                  {this.state.errors.password}
                </small>
              </div>

                <div className="subheadline mt-3">
                  <p className="blue-link" onClick={ ()=>this.setState({ showForgotPassword: true })}>
                    Forgot password?
                  </p>
                </div>

                <div className="subheadline mt-2 pt-4">
                  <div className="mb-1" >New to Hallmark Video Greetings?</div>
                      <p className="blue-link" onClick={ ()=>this.setState({ showLogIn: false }) }>
                        Create an account
                      </p>
                </div> 
            </>
            
          ):(
            <>
              <h1 className="headline-text-small-mobile">
                Create an account to save your progress
                {/* {this.props.isPreCheckout ?
                  "Want to save your project? Create a free account"
                  :
                  "Want to save it?"
                } */}
              </h1>

              {/* <h6 className="subheadline mb-3">Access your Video Greetings any time, anywhere, too.</h6> */}
              
              <h6 className="subheadline mb-3">
                Already have a Hallmark Video Greetings account?
                &nbsp; 
                  <span className="blue-link" onClick={ () => this.setState({ showLogIn: true }) } >
                    Sign&nbsp;in
                  </span>
              </h6>

              <div className="row dflex">
                <div className="col-md-6">
                  <div className={`mb-1 form-group ${this.state.errors.firstName ? "invalid": ""}`}>
                    <span className="has-float-label">
                      <label for="firstName"> First name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="firstName"
                        value={this.state.firstName}
                        onBlur ={this.validateFirstName}
                        onChange={this.handleChange}
                        id="firstName"
                        maxLength={15}
                      />
                    </span>
                    <small className="text-danger">
                      {this.state.errors.firstName}
                    </small>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={`mb-1 form-group ${this.state.errors.lastName ? "invalid": ""}`}>
                    <span className="has-float-label">
                      <label for="lastName">Last name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="lastName"
                        value={this.state.lastName}
                        onBlur ={this.validateLastName}
                        onChange={this.handleChange}
                        id="lastName"
                        maxLength={20}
                      />
                    </span>
                    <small className="text-danger">
                      {this.state.errors.lastName}
                    </small>
                  </div>
                </div>
              </div>

              <div className={`form-group ${this.state.errors.email ? "invalid": ""}`}>
                <span className="has-float-label">
                  <label for="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={this.state.email}
                    onBlur ={this.validateEmail}
                    onChange={this.handleChange}
                    id="email"
                    required
                    maxLength={180}
                  />
                </span>
                <small className="text-danger">
                  {this.state.errors.email}
                </small>
              </div>

              <div className={`mb-0 form-group ${this.state.errors.password ? "invalid": ""}`}>
                <span className="has-float-label">
                  <label for="Password">Password</label>
                  <input
                    className="form-control"
                    type="password"
                    name="password"
                    value={this.state.password}
                    onBlur ={this.validatePassword}
                    onChange={this.handleChange}
                    id="password"
                    maxLength={70}
                    required
                  />
                </span>
                <small className="text-danger">
                  {this.state.errors.password}
                </small>
                <small className="form-text text-muted pl-2">
                  6 characters minimum
                </small>
              </div>

              <div className={`form-group ${this.state.errors.confirmPassword ? "invalid": ""}`}>
                <span className="has-float-label">
                  <label for="Confirm Password">Confirm password</label>
                    <input
                      className="form-control"
                      type="password"
                      name="confirmPassword"
                      value={this.state.confirmPassword}
                      onBlur ={this.validateConfirmPassword}
                      onChange={this.handleChange}
                      id="confirmPassword"
                      maxLength={70}
                      required
                    />
                  </span>
                  <small className="text-danger">
                    {this.state.errors.confirmPassword}
                  </small>
                  <small className="text-danger">
                    {this.state.errors.passwordmatch}
                  </small>
                  <small className="form-text text-muted pl-2">
                    6 characters minimum
                  </small>
                </div>
              
              <div className="row">
                <div className="col-md-12 mt-3">
                <small>
                  By creating a Hallmark Video Greetings account, I confirm that I have read and agree to the
                  <a className="blue-link ml-1 mr-1" href="https://www.hallmark.com/terms-of-use/" target="_blank">
                  Hallmark Terms of Use
                  </a>
                  and
                  <a className="blue-link ml-1" href="https://www.hallmark.com/privacy/" target="_blank">
                  Privacy Policy
                  </a>, and agree that  Hallmark and its companies may email me about special offers and promotions. I understand that I can change my email preferences at any time.
                </small>
                </div>
              </div>

            </>
          )}

          <div className="text-center">
            <button
              type="submit"
              className="text-uppercase btn btn-block btn-lg-inline-block btn-primary mt-4 mb-3"
              disabled={ this.disableSubmitButton() }
              >
              { buttonText }
            </button>
          </div>

          { this.props.redirectDestination &&
            <Link 
              to={this.props.redirectDestination } 
              className="d-block text-center blue-link my-3"
            >

              Skip
            </Link>

          }


          {this.props.isPreCheckout && 
            <Link 
              to={{pathname: `/checkout/${this.state.fizzId}`, state: {isGuest: true}}} 
              className="d-block text-center blue-link my-3"
            >
              Checkout as Guest
            </Link>
          }

      


          {this.state.loginErrorMessage && (
            <p className="alert alert-danger">
              {this.state.loginErrorMessage}
            </p>
          )}

          { this.state.isSubmittingRegister &&
            <Spinner float={true} />
          }
                
        </form>
      </div>
      );
  }
}

export default QuickSignup;
