import React, { Component } from "react";
import Plyr from "react-plyr";
import { Link } from "react-router-dom";
import { Prompt } from 'react-router'
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";
import {toastManager} from "../components/Toaster";
import { apiFetch } from "../helpers/APIHelper";
import UploadManager from "../helpers/UploadManager";
import StandardModal from "../modal/StandardModal";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import { isLoggedIn, needsShowQuickSignUp } from "../helpers/APIHelper";
import AddButtonGroup from "../components/AddButtonGroup";
import { getBaseURL } from "../helpers/URLHelper";
import MusicPicker from "../components/MusicPicker";
import InviteOptions from "../components/InviteOptions";
import ClipsList from "../components/ClipsList";
import FizzSpecialFields from "../components/FizzSpecialFields";
import MusicListField from "../components/MusicListField";
import QuickSignup from "../components/QuickSignup";



const ARE_YOU_SURE_UPLOADING = "Your upload hasn’t finished. Are you sure you want to leave?";

class EditFizzFlow extends Component {
  constructor(props) {
    super(props);
    
    this.closeEditVideo = this.closeEditVideo.bind(this);
    this.openEditVideoModal = this.openEditVideoModal.bind(this);
    this.deleteVideo = this.deleteVideo.bind(this);
    this.checkIcon = this.checkIcon.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);

    this.state = {
      clips: [],
      editVideo: null,
      editType: null,
      inputBox: "",
      clipPopup: null,
      deleteToUrl: null,
      processMessage: "",
      fizz: null, // NULL UNTILL LOADED,
      uploadingList: [],
      checkFlag: null,
      showMusicPicker: false,
      music: null,
      deleteFizz: null,
      videoId: null,
      isUploading: false,
      currentStep: null,
      showQuickSignupModal: false,
    };
    this.loadProps(props);
  }
  componentWillReceiveProps(nextProps) {
    if (this.loadProps(nextProps)) {
      this._getData();
    }
  }
  loadProps(props) {
    var old = this.state.fizzId;

    this.state.fizzId = props.match.params.fizzId;

    this.uploadManager = new UploadManager( this.state.fizzId, 'editFizz', {
      onProgress:this.uploadProgress, 
      onAllDone:this.allDone,
      onOneDone:this.oneDone,
    })

    return old != this.state.fizzId;
  }
  componentDidMount() {
    this._getData();
    document.title = 'Your Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "";
    window.addEventListener("beforeunload", this.windowBeforeUnload);
  }
  componentWillUnmount() {
    this.stopCheckingForUpdates();

    if ( this.uploadTimer ){
      clearTimeout(this.uploadTimer);
    }
    window.removeEventListener("beforeunload", this.windowBeforeUnload);
  }

  windowBeforeUnload = (e)=>{

    if( !this.state.isUploading ){
      return;
    }

    var confirmationMessage = ARE_YOU_SURE_UPLOADING;
    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  }

  checkForUpdates() {

    if( this.stopChecking ){
      return;
    }
    if( this.state.disableNewestActor ){
      return;
    }

    if( !this.checkingSince ) {
      this.checkingSince = (new Date()).getTime();
    }

    var beenCheckingForSec = ( (new Date()).getTime() - this.checkingSince )/1000;

    var waitTimeInSec = 60;
    if( beenCheckingForSec < 60  ) {
      waitTimeInSec = 20;
    } else if( beenCheckingForSec < 5*60  ) {
      waitTimeInSec = 60;
    } else if( beenCheckingForSec < 30*60  ) {
      waitTimeInSec = 60*3;
    } else {
      // Been on the page for 30min. Leave
      window.location = "/list";
      return;
    }
    // console.log("Check again in "+waitTimeInSec+"sec");
    this.refreshTimeout = setTimeout(() => {

      apiFetch( "/rest/fizz/" + this.state.fizzId + "/newest-actor-video", {
        method: "POST",
      },
      (json) => {

        if ( json.videoId && json.videoId !== this.state.newestActorVideoId ) {
          // get data will update newestActorVideoId
          this.setState({
            newestActorVideoId:json.videoId // THIS will get set in getData... but just incase
          })
          // console.log(`FOUND SOMETHING ${json.videoId} !== ${this.state.newestActorVideoId}`);

          toastManager.showToast({ 
            title:'Good News!',
            message: 'Someone added a video or photo to your Video Greeting.' 
          });

          this.checkingSince = (new Date()).getTime();
          this._getData(true);


          
        } else {
          // console.log(`Still Just ${json.videoId}`);
          this.checkForUpdates();
        }
      },
      (error) => {
        // We wont display an error to the user for this. -EG
        console.log(error);
        this.checkForUpdates();
      });

    }, waitTimeInSec*1000);


  }

  stopCheckingForUpdates = () => {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = null;
    }
    this.stopChecking = true;
  }

  
  checkIcon(option) {
    this.setState({ checkFlag: option });
  }

  closeEditVideo() {
    this.setState({
      editVideo: null,
      editType: null,
      processMessage: "",
    });
  }

  closeClipPopup = ()=>{
    this.setState({
      clipPopup: null,
    });
  }

  openDeleteModal(fizz) {
    this.setState({
      deleteFizz: fizz,
    });
  }


  closeDeleteModal() {
    this.setState({
      deleteFizz: null,
    });
  }

  openEditVideoModal(clip, type) {
    this.setState(
      {
        editVideo: clip,
        editType: type,
        videoId: clip.id, 
        checkFlag: clip.audio_option,  
        inputBox: clip.caption ? clip.caption : "",
      }
    );
  }

  openClipPopup = (clip)=>{
    if (!clip.video) {
      clip.video = clip.introVid;
    }
    this.setState({
      clipPopup: clip,
    });
  }


  deleteVideo(fizz) {
    var deleteUrl = "/rest/video/" + this.state.editVideo.id;
    this.setState({ editVideo: null, editType: null });

    apiFetch( deleteUrl,
      {
        method: "DELETE",
      },
      (json) => {
        this.setState({
          deleteToUrl: null,
        });
        var array = this.state.clips;
        var index = array.indexOf(fizz);

        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ clips: array });
        }
      },
      (error)=>{
        alert("Failed to delete item", error);
      }
    );
  }

  saveButton(clip, type) {
    var self = this;

    self.setState({
      processMessage: "Saving...",
    });

    if (type == "text") {
      var text = self.state.inputBox;

      apiFetch(
        "/rest/video/" +
          self.state.editVideo.id +
          "/caption/" +
          encodeURIComponent(text),
        {
          method: "PUT",
        },
        function (json) {
          clip.caption = text;
          self.setState({
            processMessage: "Saved",
          });
          // console.log("CAPTION CHANGED");
        },
        function (error) {
          console.log(error);

          self.setState({
            processMessage: error,
          });
        }
      );
    } else if (type == "music") {
      apiFetch(
        "/rest/video/audio-option",
        {
          
          method: "POST",
          data: {
            video: self.state.videoId,
            audioOption: self.state.checkFlag,
          },
        },
         (json) => {
          console.log("music option set");
          clip.audio_option = this.state.checkFlag;
          this.closeEditVideo()
          this.setState({
            clips: this.state.clips //update after manal set 
          });
        },
        function (error) {
          console.log(error);
          self.setState({
            processMessage: error,
          });
        }
      );
    } else if (type == "trim") {
      var trimIframeWindow =
        document.getElementById("trimIframe").contentWindow;
      let trimStart = trimIframeWindow.startTime;
      let trimEnd = trimIframeWindow.endTime;

      apiFetch(
        "/rest/video/" + self.state.editVideo.id + "/trim",
        {
          method: "PUT",
          data: {
            trimStart: trimStart,
            trimEnd: trimEnd,
          },
        },
        function (json) {
          console.log("Trim Saved");
          self.setState({
            processMessage: "Saved",
          });
        },
        function (error) {
          console.log(error);
          self.setState({
            processMessage: error,
          });
        }
      );
    }
  }


  onShowMusicModal = () => {

    this.setState({
      showMusicPicker: true,
    });
  };

  playAudio = (music) => { 
		// Stop the old
		if(this.state.pickedMusic != music && this.state.pickedMusic != null) {
			var y = document.getElementById('music-'+this.state.pickedMusic.id);
			if( y ) {
				y.pause();
			}
		}
		this.setState({
			pickedMusic: music
		});

		// Play new
		var x = document.getElementById('music-'+music.id);
		if( x ) {
			x.play();
			return x;
		}
	}

  onCloseMusic = () => {
    this.setState({
      showMusicPicker: false,
    });
  };

  onSelectMusic = (picked) => {
    this.setState({
      selectedMusic: picked,
    });
  };


  onSaveMusic = (picked) => {
    console.log( picked );
    if (picked && this.state.music.defaultMusicId == picked.id) {
      // This gets called when the props of the music picker are first set
      this.setState({
        selectedMusic: picked,
      });
      return;
    }

    if (picked) {

      this.setState({
        musicSaveStatus: "Saving...",
      });
      apiFetch(
        "/rest/fizz/" + this.state.fizzId + "/music",
        {
          method: "PUT",
          data: {
            musicId: picked.id,
          },
        },
        (json) => {

          this.setState(prevState => ({
            musicSaveStatus: null,
            showMusicPicker: false,
            selectedMusic: picked,
            music: {
              ...prevState.music,
              defaultMusicId: picked.id
            }
          }));
          
        },
        (error) => {
          this.setState({
            musicSaveStatus: error + "",
          });
          console.log(error);
        }
      );
    }


  };


  _getData = () => {
    apiFetch(
      "/rest/fizz/" +
        this.state.fizzId +
        "/detail?type=edit-flow&cache=" +
        Math.round(Math.random() * 100000000),
      {
        method: "GET",
      },
      (json) => {
        if( json.fizz.isLocked ){
          // Fizz is locked - prevent any edits 
          window.location = "/list";
          return;
        }

        this.setState({
          fizz: json.fizz,
          clips: json.fizz.videos,
          music: json.fizz.music,

          newestActorVideoId: json.fizz.newestActorVideoId,
          disableNewestActor: json.disableNewestActor,
          currentStep: json.fizz.currentStep,

          taId: json.fizz.taId,
          specialFields: json.fizz.specialFields,
          previewImageCount: json.fizz.previewImageCount,
          skipInvite: json.fizz.skipInvite,

        },()=>{
          this.checkForUpdates();
          TrackingHelper.trackPage();
        });

      },
      (error) => {
        console.log(error);
        this.setState({
          error: error.message,
        });

        TrackingHelper.trackPage();
      }
    );
  };

  nextFromMusicFlow = () => {
    this.onSaveMusic(this.state.selectedMusic); 
    this.moveToNextFlow( this.state.currentStep );
  }

  sendMakeAlone = () => {
    //consider doing more here.
    this.moveToNextFlow( this.state.currentStep );
  }

  moveToNextFlow = ( currentStep ) => {
    var newStateStep = currentStep;

    if( currentStep < 4 ){
      newStateStep++;
    }

    if( newStateStep >= 4 ){
      // go to preview
      this.props.history.push( "/preview/" + this.state.fizzId );

    } else {
      window.scrollTo(0, 0);

      if( this.state.skipInvite && newStateStep == 2 ){
        // skip invite step for box set.
        newStateStep = 3;
      }

      this.setState({
        currentStep : newStateStep
      });
    }

    apiFetch(
      "/rest/fizz/" +
        this.state.fizzId +
        "/update-current-step?cache=" +
        Math.round(Math.random() * 100000000),
      {
        method: "POST",
        data: { currentStep: newStateStep },
      },
      (json) => {
        // fire and forget

      },
      (error) => {
        console.log(error);
      }
    );
  };
  

  startUpload = (files) => {
    this.uploadManager.startUpload(files);
    this.setState({
      isUploading: true
    })
    this.uploadTimer = setTimeout( () => toastManager.showToast({
      jumboTitle: 'Hang tight! Your upload is still in progress.',
      message: 'Remain on this page, and it will finish soon. We’re sorry for the delay—sometimes large uploads take longer to process.' ,
      keepOpen: true
    }), 5000 );
      
  }

  uploadProgress=(clips)=>{
    this.setState({
      uploadingList: clips
    });
  }
  
  allDone = ( hasAnyErrors )=>{
    console.log("ALL DONE", hasAnyErrors)
    this.setState({
      isUploading: false
    });

    if ( this.uploadTimer ){
      clearTimeout(this.uploadTimer);
    }

    if( !hasAnyErrors ) {
  
      toastManager.showToast({ 
        message: 'Upload successful!' 
      });
      
    }
  }
  oneDone = (uploader)=>{
    if( uploader.hasError() ){
      toastManager.showToast({
        message: uploader.response.message,
        error: 'error'
      })
    }

    // This could be an else... We likely will not get clips if there was an error... but just in case
    if (uploader.response.clips && uploader.response.clips.length>0) {
      this.setState({
        clips: this.state.clips.concat(uploader.response.clips),
      });

      // RETURN TRUE WILL TAKE IT OUT OF THE ARRAY
      return true;
    }
  }

  onFileUploadChange = (event) => {
    var acceptedFiles = Array.from(event.target.files);
    console.log("Select", acceptedFiles);
    this.startUpload(acceptedFiles);
    event.target.value = null;
  };


  updateParentClips( items1 ){
    this.setState(
      {
        clips: items1,
      },
      () => this.onSortEnd()
    );
  };

  onSortEnd = async () => {
    await apiFetch(
      "/rest/video/rearrange",
      {
        method: "PUT",
        data: { videos: this.state.clips },
      },
      (json) => {
        // Nothing to change the ui was already updated
      },
      (error)=>{
        console.log(error);
      }
    );
  };

  toggleQuickSignupMoal = () => {
    this.setState({
      showQuickSignupModal: !this.state.showQuickSignupModal
    });
  }

  renderMusicContent = () => {
    return (
      <div className="screen-wrap">
        <NavBar title="Your Project" />
        <MainContent className="container">

          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5 align-self-start">
              <h1 className="headline-text">Choose your Music</h1>
              <div className="pinned-bottom-bar pt-lg-4 mt-lg-4">
                <button
                  onClick={this.nextFromMusicFlow.bind(this)}
                  className="btn btn-block btn-lg-inline-block btn-primary next-btn"
                  >
                  Next
                </button>
              </div>

            </div>
            <div className="col-lg-7 mt-2 pt-lg-3">
              <MusicPicker 
                music={this.state.music}
                onSelectMusic = {this.onSelectMusic}
                />
            </div>
          </div>

        </MainContent>

        <Footer className="footer-w-pinned-bottom-bar" hideMobile={true} />
      </div>    
    );
  }

  renderInviteContent = () => {
    return (
      <div className="screen-wrap">
        <NavBar title="Your Project" />
        <MainContent className="container">
          <h1 className="headline-text headline-text-small-mobile">Invite Friends to Contribute</h1>
          <InviteOptions 
            fizzId={this.state.fizzId}
            moveToNextFlow={()=>{this.moveToNextFlow( this.state.currentStep )}}
            onInvite={()=>{
              this.setState({
                showQuickSignupModal: !isLoggedIn()
              });
            }}
          />


          {/* QuickSign Modal */}
          <StandardModal
              show={this.state.showQuickSignupModal }
              onHide={this.toggleQuickSignupMoal}
              closeBtnText={false}
              size="lg"
              >
              <QuickSignup successCallback={this.toggleQuickSignupMoal} preventRedirect = {true} from={'EditFizz'} fullButton={true}/>
          </StandardModal>
        </MainContent>
        <Footer className="footer-w-pinned-bottom-bar medium" hideMobile={true} />
      </div>    
    );
  }

  render() {
    if( this.state.currentStep && this.state.currentStep == 1 ){
      return(
        this.renderMusicContent()
      )
    }

    if( this.state.currentStep && this.state.currentStep == 2 ){
      return(
        this.renderInviteContent()
      )
    }

    var emptyState = this.state.clips.length <=0  && this.state.uploadingList.length <=0;
    var allowSignUpAlert = false;
    
    // this checks that the user is not signed and if they have uploaded something or they have already gone to the preview screen.
    if( !isLoggedIn() && ( this.state.clips.length > 0 || this.state.currentStep > 3 ) ){
        allowSignUpAlert = true;
    }


    return (
      <div className="screen-wrap">
        <NavBar title="Your Project" 
          allowSignUpAlert = { allowSignUpAlert }
          fizzId = { this.state.fizzId }
        />
        
        <Prompt when={this.state.isUploading} message={ARE_YOU_SURE_UPLOADING} />

        <MainContent className="container">

          {/* Empty screen option  */}
          {this.state.fizz ? (
            <div className="row justify-content-center bg-margin">
              <div className="col-lg-6">
                { ( this.state.currentStep && this.state.currentStep > 3 ) ? (
                  <>
                    <h1 className="headline-text-small-mobile mb-md-4">Edit My Video Greeting</h1>
                  
                    <FizzSpecialFields fizzId={this.state.fizzId} taId={this.state.taId} specialFields={this.state.specialFields} previewImageCount={this.state.previewImageCount} />
                  
                    <MusicListField displayErr={this.state.displayErr} music={this.state.music} showMusicList={this.onShowMusicModal} />
                  </>
                ) : (
          
                  <h1 className="headline-text mb-sm-4">Add photos and videos</h1>
                
                )}

                <div className={"text-center mt-4 mb-3 align-self-center" + ( emptyState ? " pt-lg-5" : "" )}>
                  <div className={"pinned-bottom-bar" + ( emptyState ? " pt-lg-5" : "" )}>
                    <AddButtonGroup
                      fizzId={this.state.fizzId}
                      onFileUploadChange={this.onFileUploadChange.bind(this)}
                      isInvite={false}
                      isAddPhoto={ this.state.currentStep > 3 ? false : true }
                      primaryText={ this.state.clips.length <= 0 ? "ADD PHOTOS OR VIDEOS" : "ADD MORE PHOTOS OR VIDEOS"}
                      errorsCleared={true}
                      moveToNextFlow={()=>{this.moveToNextFlow( this.state.currentStep )}}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mt-2 pt-lg-5">
                  { emptyState ?  (
                    <div className="text-center mt-sm-4 mb-3 align-self-center pt-lg-5">
                    {/* <div className="mt-4 mb-4 text-center d-flex justify-content-center"> */}
                      <h5 className="headline-text mt-sm-4 pt-lg-4">
                        Add as many photos and videos as you like! There’s no limit and you can come back to add more later.
                      </h5>
                    </div>
             
                  ) : (
                    <ClipsList
                      updateParentClips = { this.updateParentClips.bind( this ) }
                      clips = { this.state.clips }
                      openEditVideoModal = { this.openEditVideoModal.bind( this ) }
                      uploadingList = { this.state.uploadingList }
                      uploadManager = { this.uploadManager}
                      openClipPopup = { this.openClipPopup.bind( this ) }
                      onFileUploadChange={this.onFileUploadChange.bind( this )}
                    />
                    
                  )}

              </div>

                {!isLoggedIn() &&
                  <small className="mx-auto text-center pt-3">
                    By continuing, I agree to Hallmark’s &nbsp;
                    <a className="blue-link" href="https://www.hallmark.com/terms-of-use/" target="_blank" >Terms&nbsp;of&nbsp;Use</a>
                    &nbsp;and&nbsp;
                    <a className="blue-link" href="https://www.hallmark.com/privacy/" target="_blank" >Privacy&nbsp;Policy</a>.
                  </small>}
            </div>
          ) : (
            <div>
              {this.state.error ? (
                <div className=" text-center alert alert-danger mt-4">
                  {this.state.error}
                </div>
              ) : (
                <Spinner float={true} />
              )}
            </div>
          )}

          <StandardModal
            size="full"
            show={this.state.showMusicPicker}
            onConfirm={this.onSaveMusic.bind(this, this.state.selectedMusic)} 
            onHide={this.onCloseMusic}
            confirmBtnText="Save"
          >
            <MusicPicker  
              onSelectMusic = {this.onSelectMusic}
              music = { this.state.music }
            />
          </StandardModal>

          <StandardModal
            size="lg"
            show={this.state.clipPopup}
            onHide={this.closeClipPopup}
            closeBtnText="Close"
          >
            { this.state.clipPopup &&
              (this.state.clipPopup.isImage ? (
                <img src={this.state.clipPopup.image} width="100%"/>
                ):(
                <Plyr
                  type="video" // or "vimeo"
                  url={this.state.clipPopup ? this.state.clipPopup.video : null}
                  autoplay
                />
              )
            )}
          </StandardModal>


        {/* Delete Clip Modal */}
          <StandardModal
            show={this.state.editVideo && this.state.editType == "delete" }
            onHide={this.closeEditVideo}
            header="Are you sure you want to delete this item?"
            confirmBtnText="YES, DELETE"
            onConfirm={this.deleteVideo.bind(this, this.state.editVideo)} 
          >
          Deleted items cannot be recovered.
          </StandardModal>

        {/* Edit Sound Modal */}
          <StandardModal
            show={this.state.editVideo && this.state.editType == "music" }
            onHide={this.closeEditVideo}
            header="Sound options"
            confirmBtnText="DONE"
            onConfirm={this.saveButton.bind(this, this.state.editVideo, this.state.editType )} 
          >
            <ul className="list-group list-group-flush sound-picker-list">
              <a tabIndex="0" onClick={this.checkIcon.bind(this, 0)}>
                <li className="list-group-item text-center">
                  {this.state.checkFlag === 0 ? (
                    <span className="icon-checkmark"></span>
                  ) : null}{" "}
                  Play background music + video&nbsp;sound
                </li>
              </a>
              <a tabIndex="0" onClick={this.checkIcon.bind(this, 1)}>
                <li className="list-group-item text-center">
                  {this.state.checkFlag === 1 ? (
                    <span className="icon-checkmark"></span>
                  ) : null}
                  Mute video sound
                </li>
              </a>
              <a tabIndex="0" onClick={this.checkIcon.bind(this, 2)}>
                <li className="list-group-item text-center">
                  {this.state.checkFlag === 2 ? (
                    <span className="icon-checkmark"></span>
                  ) : null}
                  Mute background music
                </li>
              </a>
            </ul>
            <p>{this.state.processMessage}</p>
          </StandardModal>

        </MainContent>
        <Footer className="footer-w-pinned-bottom-bar" hideMobile={true} />
      </div>
    );
  }
}

export default EditFizzFlow;
