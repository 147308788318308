import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Plyr from "react-plyr";
import "../assets/css/LandingPage.css";
import "react-multi-carousel/lib/styles.css";
import StandardModal from "../modal/StandardModal";
import VideoBGSoTheColorsMatch from "../assets/videos/hero-color-230.mp4";

export class LandingBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onGetStarted = () => {
    window.location = '/list';
  }


  render() {

    var headline = (<>Video Greetings is going away<br/>(but don’t worry!)</>);
    var message = (<>Your completed projects will still be available to download until service ends on August 20, 2025. Save your video greetings before then so you can keep on sharing and watching, again&nbsp;and&nbsp;again.</>);
    var bgColor = 'https://static.hallmarkvideogreetingcards.com/pub/landing-page-digital-hero-lynn-bg.mp4';
    var sideVideo = 'https://static.hallmarkvideogreetingcards.com/pub/landing-page-digital-hero-bday-lynn.mp4';

      return(
        <section className="hero p-0">
          <video className="hero-bg-video disable-gtm" 
                  loop={true}
                  muted={true}
                  autoPlay
                  playsInline ><source src={bgColor} type="video/mp4" /></video>
          <div className="container-fluid p-0 container-lg" >
            <div className="row no-gutters">
              <div className="col-md-8">
                <div className={"hero__text "+(this.props.isQRCard?'hero__text__qr':'hero__text__digital')} >
                  <h2 className="headline">{headline}</h2>
                  <p className="hero__description mt-4">{message}</p>
                    <button className="btn btn-primary" onClick={this.onGetStarted} >Check my projects</button>
                </div>
              </div>

              <div className="col-md-4 d-md-block hero__image">
                <video className="disable-gtm"
                  style={{zIndex:100}}
                  loop
                  muted
                  autoPlay
                  playsInline
                  width="100%"
                  // onLoad={(e)=>{e.currentTarget.play()}}
                ><source src={sideVideo} type="video/mp4" /></video>
              </div>
            </div>
          </div>

        </section>
      );
    }
}

export default LandingBanner;
