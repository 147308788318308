import React, { Component } from "react";
import { apiFetch, saveSession  } from "../helpers/APIHelper";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import LandingBanner from "../components/LandingBanner";
import LandingSteps from "../components/LandingSteps";
import LandingAssortment from "../components/LandingAssortment";
import LandingVideo from "../components/LandingVideo";
import MainContent from "../components/MainContent";
import ShopTheCardsBanner from "../components/ShopTheCardsBanner";
import LandingCustomBanner from "../components/LandingCustomBanner";
import TrackingHelper from "../helpers/TrackingHelper";


export default function SorryPage(props) {

  return (
    <div>
      <NavBar />
        <MainContent className="container bg-margin">
        

        <div className="row">
          <div className="col-md-6">


            <h1 className="headline-text">Page not found.</h1>
            <h6 className="subheadline mb-3">We’re sorry, this page is no longer available.</h6>

            <p style={{fontWeight:200,fontFamily:"BeamNewHMK-84"}}>
              The last day to complete a Video Greeting was February 20, 2025.
              <br/>Finished projects remain available to download until August 20, 2025.
            </p>

            <p style={{fontWeight:200,fontFamily:"BeamNewHMK-84"}}>
              Need help? Call <a href="tel:+18775808847" style={{textDecoration:'underline'}}> 1-877-580-8847</a>
            </p>

          </div>

          <div className="col-md-6 text-center d-none d-md-block">
          </div>
        </div>

      </MainContent>
      <Footer />
    </div>
  );
}
